.edit-exam{
    margin: 1% 15% 3% 15%;
    padding: 50px 75px;
    position: relative;
    .edit-exam-header-box{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        a{
          top: 0px;
          position: absolute;
          color: #000000;
          font-size: 120%;
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          vertical-align: middle;
          align-items: center;
          font-weight: bold;
          ion-icon{
            margin-right: 10px;
            font-size: 120%;
          }
          &:hover{
            opacity: 0.6;
          }
        }
        .edit-exam-header{
          h1{
              font-size:30px;
          }
      }
    }
    .edit-exam-body-box{
      margin-top: 8px;
      padding: 20px;
      background-color: #ffffff;
      border: 1px solid #dddfe2;
      border-radius: 5px; 
        .edit-exam-body{
          width: 70%;
          display: flex;
          flex-direction: column;
          .errormsg{
            padding-bottom: 7.5px;
            width: 100%;
            color: red;
            margin-left: 40%;
          }
          .edit-exam-body-element{
              width: 100%;
              display: flex;
              flex-direction: row;
              margin: 10px 0;
              label{
                  max-width: 40%;
                  min-width: 40%;
              }
              input{
                  width: 50%;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  margin-right: 100px;
              }
              input[type=number]{
                  width: 10%;
              }
          }
      }
      button {
        margin-top: 20px;
        border-radius: 4px;
        background-color: #3399ff;
        border: none;
        color: #FFFFFF;
        text-align: center;
        font-size: 16px;
        padding: 10px 5px;
        width: 180px;
        transition: all 0.5s;
        cursor: pointer;

        span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
            }
        }
      button span:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: 0;
        right: -20px;
        transition: 0.5s;
      }
      button:hover span {
        padding-right: 25px;
      }
      button:hover span:after {
        opacity: 1;
        right: 0;
      }
    }
    .edit-subject-footer{
        p{
            color: #2D3142;
        }
      }
}