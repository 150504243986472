.user-overview{
  margin: 1% 15% 3% 15%;
  position: relative;
  padding: 50px 75px;
  .user-overview-header-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dddfe2;
    border-radius: 5px; 
      .go-back{
        width: 8%;
        color: #000000;
        position: absolute;
        top: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;
        font-weight: bold;
        ion-icon{
          margin-right: 10px;
          font-size: 120%;
        }
        &:hover{
          opacity: 0.6;
        }
      }
    h1{
      font-size: 30px;
    }
    button {
      border-radius: 6px;
      background-color: #3399ff;
      border: none;
      color: #FFFFFF;
      text-align: center;
      font-size: 16px;
      padding: 10px 5px;
      width: 180px;
      transition: all 0.5s;
      cursor: pointer;

      span {
          cursor: pointer;
          display: inline-block;
          position: relative;
          transition: 0.5s;
          color: #FFFFFF;
          }
      }
    button span:after {
      content: '\00bb';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s;
    }
    button:hover span {
      padding-right: 25px;
    }
    button:hover span:after {
      opacity: 1;
      right: 0;
    }
  }
  .user-overview-body-box{
    margin-top: 8px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dddfe2;
    border-radius: 5px; 
    width: 100%;
    .user-overview-body{
      width:100%;
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: fixed;
        tr {
          width: 100%;
          max-width: 100%;
          td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
            p {
              overflow:hidden; 
              white-space:nowrap; 
              text-overflow: ellipsis;
              max-width: 100%;
            }
            &:nth-child(1) {
              width: 25%;
              max-width: 25%;
            }
            &:nth-child(2) {
              width: 15%;
              max-width: 15%;
            }
            &:nth-child(3) {
              width: 15%;
              max-width: 15%;
            }
            &:nth-child(4) {
              width: 15%;
              max-width: 15%;
            }
            &:nth-child(5) {
              width: 10%;
              max-width: 10%;
            }
            &:nth-child(6) {
              width: 10%;
              max-width: 10%;
            }
            &:nth-child(7) {
              width: 10%;
              max-width: 10%;
            }
          }
        }
      }
    }
    .loader-animation {
      display: flex; 
      justify-content: center;
      align-items: center;
    }
  }
}