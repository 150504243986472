.question-question-overview{
    margin: 1% 15% 3% 15%;
    position: relative;
    padding: 50px 75px;
    .question-question-overview-header-box{
        margin-bottom: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .go-back{
            width: 8%;
            color: #000000;
            position: absolute;
            top: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            vertical-align: middle;
            align-items: center;
            font-weight: bold;
            ion-icon{
                margin-right: 10px;
                font-size: 120%;
              }
              &:hover{
                opacity: 0.6;
              }
        }
        .question-question-overview-header{
            width: 100%;
            display: flex;
            h1{
               flex: 1;
            }
            p{
                flex: 1;
                font-size: 140%;
                direction: rtl;
                font-weight: bold;
            }
        }
    }

    .question-question-overview-body-box{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .question-question-overview-body{
            .question-question-overview-body-content{
                display: flex;
                padding: 15px 0 15px 0;
                border-bottom: 1px solid #dddfe2;
                &:last-child{
                    border-bottom: transparent;
                }
                &:first-child{
                    padding-top: 0px;
                }
                .question-question-overview-body-content-index{
                    flex: 0.2;
                    p{
    
                    }
                }
                .question-question-overview-body-content-question-answer{
                    flex: 4;
                    .abcd{
                        p{
                            display: flex;
                            .test{
                                flex: 0.5;
                            }
                            span{
                                flex: 10;
                                margin-right: 10px;
                        }
                        }
                    }
                   .question{
                       margin-bottom: 15px;
                       font-weight: bold;
                   }
                   .correct-answer{
                       color: green;
                   }
                   .answers{
                       text-decoration: underline;
                       margin-bottom: 7.5px;
                   }
                   .reason{
                       text-decoration: underline;
                       margin-bottom: 7.5px;
                       margin-top: 15px;
                   }
                   img{
                       margin-bottom: 7.5px;
                       margin-top: 15px;
                       max-width: 650px;
                       min-width: 400px;
                       max-height: 700px;
                   }
                }
                .question-question-overview-body-content-edit-delete-parent{
                    border-left: 1px solid #dddfe2;
                    padding-left: 25px;
                    max-width: 200px;
                    min-width: 200px;
                    .question-question-overview-body-content-checkboxes{
                        flex: 0.6;
                        #dynamic-exam{
                            display: flex;
                            #checkbox{
                                margin-right: 5px;
                            }
                            span{

                            }
                        }

                        p{
    
                        }
                        .moeilijkheidsgraad{
                            text-align: center;
                        }
                        .question-question-overview-body-content-edit-delete{
                            text-align: center;
                            ion-icon{
                                color: #000000;
                                font-size: 25px;
                                padding: 20px 20px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .loader-animation {
                display: flex; 
                justify-content: center;
                align-items: center;
            }
        }
    }

}