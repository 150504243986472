@mixin boxstyle($boxWidth, $spanWidth, $spanWidthSecond){                                
    div{
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 25px 0;
        &:nth-child(1) {
            margin-right: 25px;
        }

        label{
            font-size: .6rem;
            position: absolute;
            top: -15px;
            left: 15px;
            z-index: 2;
        }

        input{
            width: $boxWidth;
            outline: none;
            border: 1px solid grey;
            border-radius: 5px;
            padding: .5rem;
            top: 0;
            &:focus { 
                border: 1px solid #3399ff;
                box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
                outline: none;
                & + label{
                    color: #3399ff;
                }
            }
        }
        &::before{
            content: "";
            position: absolute;
            width: $spanWidth;
            height: 3px;
            top: 0;
            left: 10px;
            z-index: 1;
            background-color: #fff;
        }
        &:nth-child(2) {
            &::before{
                width: $spanWidthSecond;
            }
        }
    }
}


.welcome-hero{
     .welcome-hero-content{
        background-image: url('../images/WelcomeHero.jpg');
        background-position: center;
        background-size: cover;
        background-attachment: local;
        width: 100%;
        height: 100vh;
        margin:auto;

         .welcome-hero-content-header{
            display: flex;
            justify-content: space-between;
            height: 80px;
                        img{
                            margin: 1% 0 0 2%;
                            min-height: 100%;
                            max-height: 100%;
                    }
            .welcome-hero-content-header-navigation{
                ul{
                    min-height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    vertical-align: middle;
                    align-items: center;
                    list-style: none;
                    li{
                        cursor: pointer;
                        font-size: 110%;
                        margin: 0 30px 0 10px;
                        padding-bottom: 2px;
                        border-bottom: 2px solid transparent;
                        transition: border-bottom 0.25s, opacity 0.25s;
                        a{
                            text-decoration: unset;
                            color: #ffffff;
                        }
                        &:last-child {
                            margin-right: 30px;
                            margin-left: 30px;
                        }
                        &:hover{
                            border-bottom: 2px solid #ffffff;
                            opacity: 0.5;
                        }
                    }
                    p{
                        color: #ffffff;
                    }
                }
            }
        
         }
         .welcome-hero-content-body{
            margin-left: 5%;
             h2{
                margin-bottom: 20px;
                font-weight: 100;
                span{
                    font-size: 110%;
                    font-weight: bold;
                }
             }
             button {
                margin-top: 40px;
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;
    
                span {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    color: #ffffff;
                    transition: 0.5s;
                    }
                }
              button span:after {
                //content: '\00bb';
                position: absolute;
                opacity: 0;
                top: 0;
                right: -20px;
                transition: 0.5s;
              }
              button:hover span {
                padding-right: 25px;
              }
              button:hover span:after {
                opacity: 1;
                right: 0;
              }

              &-title{
                margin: 100px 0 50px 0;
              }
              &-form{
                  margin-bottom: 50px;
                .registrationform{
                    margin-top: 100px;
                    max-width: 800px;
                    h1{
                        margin-bottom: 50px;
                    }
                    &-content{
                        &-errors{
                            color: red;
                        }
                        &-body{
                            &-name {
                                display: flex;

                                @include boxstyle(260px, 66px, 74px);
                            }
                            &-email{
                                margin: 25px 0;
                                @include boxstyle(545px, 40px, 0);
                            }
                            &-usernameclub{
                                display: flex;
                                @include boxstyle(260px, 100px, 35px);
                                select{
                                    width: 260px;
                                    outline: none;
                                    border: 1px solid grey;
                                    border-radius: 5px;
                                    padding: .5rem;
                                    
                                        &:focus { 
                                            border: 1px solid #3399ff;
                                            box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
                                            outline: none;
                                            & + label{
                                                color: #3399ff;
                                            }
                                        }
                                }
                            }
                            &-password{
                                display: flex;
                                @include boxstyle(260px, 62px, 110px);
                            }
                        }
                    }
                    button{
                        margin-bottom: 25px;
                    }
                }

                .loginform{
                    margin-top: 200px;
                    max-width: 800px;
                    h1{
                        margin: 25px 0 50px 0;
                    }
                    &-content{
                        &-error{
                            color: red;
                        }
                        &-fields div{
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            margin: 25px 0;

                            label{
                                font-size: .6rem;
                                position: absolute;
                                top: -15px;
                                left: 15px;
                                z-index: 2;
                            }

                            input{
                                max-width: 260px;
                                outline: none;
                                border: 1px solid grey;
                                border-radius: 5px;
                                padding: .5rem;
                                top: 0;
                                &:focus { 
                                    border: 1px solid #3399ff;
                                    box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
                                    outline: none;
                                    & + label{
                                        color: #3399ff;
                                    }
                                }
                            }
                            &::before{
                                content: "";
                                position: absolute;
                                width: 100px;
                                height: 3px;
                                top: 0;
                                left: 10px;
                                z-index: 1;
                                background-color: #fff;
                            }
                            &:nth-child(2) {
                                &::before{
                                    width: 65px;
                                }
                            }
                        }
                        button{
                            margin: 25px 0;
                        }
                    }
                    &-links{
                        margin-bottom: 50px;
                        font-size: .6rem;
                        &-button{
                            text-decoration: underline;
                            font-weight: bold;
                            cursor: pointer;
                        }
                    }
                }
            }
         }
    }
}
