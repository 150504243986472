.exam-chances {
  .exam-chances-content {
    margin-left: 10px;
    margin-bottom: 150px;
    .user-overview-table {
      display: grid;
      grid-template-columns: 0.2fr 0.2fr 0.2fr;
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 30px;
      position: relative;
      p {
        margin-top: 50px;
      }
      .go-back{
        width: 8%;
        color: #000000;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;
        font-weight: bold;
        ion-icon{
            margin-right: 10px;
            font-size: 120%;
          }
          &:hover{
            opacity: 0.6;
          }
    }
    }
    .exam-chances-content-element {
      margin-top: 5px;
      display: grid;
      grid-template-columns: 0.2fr 0.2fr 0.2fr;
      .exam-chances-content-element-button {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .exam-chances-content-individual-scores {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: auto;
      margin-bottom: 10px;
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      .pdfbutton {
        img {
          width: 50px;
          height: 50px;
          margin: 0;
        }
      }
    }
  }
  .loader-animation {
      height: 60vh;
      display: flex; 
      justify-content: center;
      align-items: center;
  }
}
