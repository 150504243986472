.forgotPasswordPage{
    height: 100vh;
    background: rgba(#000, .7);

    .forgot-password{
        background-color: white;
        max-width: 800px;
        padding: 3rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &-header{
            margin-bottom: 2rem;
        }
        &-content{
            &-errors{}
            &-input{
                display: flex;
                flex-direction: column;
                div{
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    
                    &:first-child{
                        margin: 0 0 3rem 0;
                    }
                    label{
                        font-size: .6rem;
                        position: absolute;
                        top: -15px;
                        left: 15px;
                        z-index: 2;
                    }
                    input{
                        width: 100%;
                        outline: none;
                        border: 1px solid grey;
                        border-radius: 5px;
                        padding: .5rem;
                        top: 0;
                        position: relative;
                        &:focus { 
                            border: 1px solid #3399ff;
                            box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
                            outline: none;
                            & + label{
                                color: #3399ff;
                            }
                        }
                    }
                }
            }
            button {
                margin-top: 20px;
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;
                &:first-child{
                    margin-right: 20px;
                }
                &:hover span{
                    padding-right: 25px;
                    &::after{
                        opacity: 1;
                        right: 0;
                     }
                }
                span {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                    &::after{
                        content: '\00bb';
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        right: -20px;
                        transition: 0.5s;
                    }
                }
            }
            button:disabled {
                background: grey;
            }
        }
    }
}


// .forgot-password{
//     background-color: #fff;
//     max-width: 800px;
//     padding: 3rem;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     &-header{
//         margin-bottom: 2rem;
//     }
//     &-body{
//         h2{
//             margin-bottom: 1rem;
//             font-size: 1rem;
//         }
//         &-input{
//             position: relative;
//         label{
//             font-size: .6rem;
//             position: absolute;
//             top: -15px;
//             left: 15px;
//             z-index: 2;
//         }

//         input{
//             width: 100%;
//             outline: none;
//             border: 1px solid grey;
//             border-radius: 5px;
//             padding: .5rem;
//             top: 0;
//             &:focus { 
//                 border: 1px solid #3399ff;
//                 box-shadow: 0 8px 14px 0 rgba(108, 93, 211, 0.07);
//                 outline: none;
//                 & + label{
//                     color: #3399ff;
//                 }
//             }
//         }
//         &::before{
//             content: "";
//             position: absolute;
//             width: 40px;
//             height: 3px;
//             top: 0;
//             left: 10px;
//             z-index: 1;
//             background-color: #fff;
//         }
//         }
        