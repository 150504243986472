.login-form-content{
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        vertical-align: middle;
        align-items: center;

        .login-form-content-box{
            box-shadow: 2px 2px 8px 0px #3b3b3b;
            background-color:#2b75c0;
            min-height: 50vh;
            width: 20vw;
            border-radius: 25px;
            padding: 10px 30px 5px 30px;
            text-align: center;

            .login-form-content-box-content-extra{
                 display: flex;
                 flex-direction: column;
                 align-items: flex-start;
                p{
                    font-size: 12px;
                    color: #ffffff;
                    float: left;
                        a{
                            font-size: 14px;
                            font-weight: bold;
                            color: #ffffff;
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    &:first-child{
                        margin-top: 20px;
                    }
                }
            }

            h1{
                color: #ffffff;
                font-size: 20px;
                font-family: Arial, Helvetica, sans-serif;
                margin-bottom: 20px;
            }
            .login-form-content-box-icon{
                ion-icon{
                    font-size: 600%; 
                    color: #ffffff;
                }
                
            }
            .login-form-content-box-content{
                .login-form-content-box-content-errormsg{
                    color: #ffffff;
                    margin-bottom: 15px;
                }
                input{
                    padding: 5px 10px;
                    background-color: #ffffff;
                    border-radius: 5px;
                    width: 100%;
                    height: 40px;
                    margin-bottom: 10px;
                    outline: none;
                    box-shadow: 1px 1px 4px 0px #000000;
                }
                button{
                    background-color: #ffffff;
                    border: 2px solid #555555;
                    padding: 15px 75px;
                    text-decoration: none;
                    font-size: 14px;
                    margin: 4px 2px;
                    cursor: pointer;
                    border-radius: 35px;
                    margin-top: 25px;
                    outline: none;
                }
                
            }
            
            .login-form-content-message{
                margin-top: 20px;
                float: left;
                color: #ffffff;
            }
    }
    
}