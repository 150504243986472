.navbar{
    width: 100%;
    box-shadow: 0px 0px 6px 0px #929292;
    
    .navbar-content{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 75px;
        background-color: #3399ff;
        a{
            width: 300px;
            img {
                margin: 10px 0 0 20px;
                max-height: 80%;
                min-height: 80%;
            }     
        }
        .navbar-content-navigation{
            flex: 9;
            .navbar-content-navigation-links{
                min-height: 100%;
                float: right;
                display: flex;
                list-style: none;
                align-items: center;
                li{
                    margin-left: 50px;
                    a{
                        text-decoration: none;
                        color: #ffffff;
                        padding-bottom:20px;
                        padding-top: 20px;
                        &:hover{
                            border-bottom: 2px solid;
                        }
                        &.active-link{
                            border-bottom: 2px solid;
                        }
                    }
                    &:last-child{
                        margin-right: 20px;
                    }
                }
            }

        }
    }
}
