.select-users-for-exam{
    margin: 1% auto;
    width: 70%;
    padding: 50px 75px;
    @include xlPhone{
      width: 95%;
      padding: 10px 20px;
    }
    .select-users-for-exam-header-box{
      margin-top: 8px;
      padding: 20px;
      background-color: #ffffff;
      border: 1px solid #dddfe2;
      border-radius: 5px; 
        .go-back{
          width: 8%;
          color: #000000;
          position: absolute;
          top: 100px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          vertical-align: middle;
          align-items: center;
          font-weight: bold;
          ion-icon{
              margin-right: 10px;
              font-size: 120%;
            }
            &:hover{
              opacity: 0.6;
            }
      }
      .select-users-for-exam-header{
          h1{
            font-size: 30px;
          }
      }
    }
    .select-users-for-exam-body-box{
      margin-top: 8px;
      padding: 20px;
      background-color: #ffffff;
      border: 1px solid #dddfe2;
      border-radius: 5px;
      width:100%;
      .select-users-for-exam-body{
        width:100%;
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
          max-width: 100%;
          table-layout: fixed;
          tr {
            width: 100%;
            max-width: 100%;
            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
              p {
                overflow:hidden; 
                white-space:nowrap; 
                text-overflow: ellipsis;
                max-width: 100%;
              }
              &:nth-child(1) {
                width: 5%;
                max-width: 5%;
              }
              &:nth-child(2) {
                width: 30%;
                max-width: 30%;
              }
              &:nth-child(3) {
                width: 15%;
                max-width: 20%;
              }
              &:nth-child(4) {
                width: 15%;
                max-width: 20%;
              }
              &:nth-child(5) {
                width: 30%;
                max-width: 20%;
              }
              &:nth-child(6) {
                width: 5%;
                max-width: 5%;
              }
            }
            button {
              margin-top: 40px;
              border-radius: 4px;
              background-color: #3399ff;
              border: none;
              color: #FFFFFF;
              text-align: center;
              font-size: 16px;
              padding: 10px 5px;
              width: 180px;
              transition: all 0.5s;
              cursor: pointer;
      
              span {
                  cursor: pointer;
                  display: inline-block;
                  position: relative;
                  transition: 0.5s;
                  }
              }
            button span:after {
              content: '\00bb';
              position: absolute;
              opacity: 0;
              top: 0;
              right: -20px;
              transition: 0.5s;
            }
            button:hover span {
              padding-right: 25px;
            }
            button:hover span:after {
              opacity: 1;
              right: 0;
            }
          }
        }
      }
    }
}