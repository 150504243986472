.question-overview{
    margin: 1% 15% 0% 15%;
    position: relative;
    padding: 50px 75px;
    .question-overview-box{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px;
        .go-back{
            width: 8%;
            color: #000000;
            position: absolute;
            top: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            vertical-align: middle;
            align-items: center;
            font-weight: bold;
            ion-icon{
                margin-right: 10px;
                font-size: 120%;
              }
              &:hover{
                opacity: 0.6;
              }
        }
        .question-overview-header{
            h1{
            }
        }
    }
    .question-overview-body-box{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .question-overview-body{
            display: grid;
            grid-template-columns:1fr 1fr 1fr;
            grid-gap: 4em;
            grid-auto-rows: 40px;
            margin-bottom: 100px;
            .question-overview-body-content{
                .question-overview-body-content-p1{
                    max-height: 100px;
                    min-height: 100px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    vertical-align: middle;
                    align-items: center;
                    color: #ffffff;
                    border-radius: 15px;
                    border: 3px solid transparent;
                    background: #3399ff;
                    padding: 0.2em;
                    cursor: pointer;
                    user-select: none;
                    a{
                        text-decoration: none;
                        color: #ffffff;
                    }
                    .question-overview-body-content-p1-naam{
                        p{
                            text-align: center;
                        }
                    }
                    .question-overview-body-content-p1-nummer{
                        .number{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            vertical-align: middle;
                            align-items: center;
                        }
                    }
                }
            }
        }
        .loader-animation {
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .question-overview-footer{
            button{
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;
                &:hover span{
                    padding-right: 25px;
                    &::after{
                        opacity: 1;
                        right: 0;
                    }
                }
                span{
                    cursor: pointer;
                    color: #ffffff;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                    &::after{
                        content: '\+';
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        right: -20px;
                        transition: 0.5s;
                        }
                    }
            }
        }
    }
}
