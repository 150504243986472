.add-question{
    margin: 1% 15% 3% 15%;
    position: relative;
    padding: 50px 75px;
    .add-question-header-box{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        a{
            top: 0px;
            position: absolute;
            color: #000000;
            font-size: 120%;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            vertical-align: middle;
            align-items: center;
            font-weight: bold;
            ion-icon{
              margin-right: 10px;
              font-size: 120%;
            }
            &:hover{
              opacity: 0.6;
            }
          }
          .add-question-header{
            h1{
            }
        }
    }
    .add-question-body-box{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .add-question-body{
            .add-question-body-errormsg{
                padding-bottom: 7.5px;
                color: red;
                margin-left: 30%;
            }
            .add-question-body-element{
                .warning-text{
                    font-weight: bold;
                }
                .add-question-body-element-content{
                    &:first-child{
                        margin-bottom: 25px;
                    }
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    margin: 5px 0;
                    label{
                        min-width: 30%;
                        max-width: 30%;
                    }
                    #image{
                        padding-top: 5px;
                    }
                    input{
                        width: 60%;
                        justify-content: flex-start;
                    }
                }
                .add-question-body-element-footer{
                    width: 100%;
                    .add-question-body-element-footer-content{
                        display: flex;
                        p{
                            min-width: 30%;
                            max-width: 30%;
                        }
                        select{
                            width: 60%;
                        }
                    }
                    p{
                        width: 50%;
                    }
                }
                .add-question-body-element-content-dropdown{
                    margin-bottom: 5px;
                    display: flex;
                    p{
                        min-width: 30%;
                        max-width: 30%;
                    }
                    #correctAnswer{
                        width: 60%;
                    }
                }
                .add-question-body-element2-explanation{
                    display: flex;
                    margin: 25px 0 25px;
                    label{
                        min-width: 30%;
                        max-width: 30%;
                    }
                    textarea{
                        width: 60%;
                        height: 65px;
                    }
                    
                }
            }
            .add-question-body-element2{
                width: 100%;
                margin-bottom: 10px;
                margin-top: 20px;
                .add-question-body-element2-tags{
                    margin-bottom: 10px;
                    .examen{
                        float: left;
                        width: 40%;
                    }
                    .moeilijkheidsgraad{
                        margin-left: 40%;
                        width: 60%;
                    }
                }
                .add-question-body-element2-moeilijkheidsgraad{
                    width: 60%;
                    float: right;
                    input{
                        margin-right: 15px;
                    }
                }
                .add-question-body-element2-checkbox{
                    width: 40%;
                    input{
                        margin-right: 15px;
                    }
                }
            }

            .add-question-body-footer{

                button {
                margin-top: 50px;
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;

                span {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                    }
                }
                button span:after {
                    content: '\+';
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    right: -20px;
                    transition: 0.5s;
                }
                button:hover span {
                    padding-right: 25px;
                }
                button:hover span:after {
                    opacity: 1;
                    right: 0;
                }
            }
        }
        .loader-animation {
            display: flex; 
            justify-content: center;
            align-items: center;
        }
    }
}