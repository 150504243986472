.delete-exam{
    margin: auto;
    margin-top: 275px;
    width: 30%;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dddfe2;
    border-radius: 5px; 
    .delete-exam-body{
        button {
            margin-top: 50px;
            border-radius: 4px;
            background-color: #3399ff;
            border: none;
            color: #FFFFFF;
            text-align: center;
            font-size: 16px;
            padding: 10px 5px;
            width: 180px;
            transition: all 0.5s;
            cursor: pointer;
            &:first-child{
                margin-right: 20px;
            }
            &:hover span{
                padding-right: 25px;
                &::after{
                    opacity: 1;
                    right: 0;
                 }
            }
            span {
                cursor: pointer;
                display: inline-block;
                position: relative;
                transition: 0.5s;
                &::after{
                    content: '\00bb';
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    right: -20px;
                    transition: 0.5s;
                }
            }
        }
    }
}