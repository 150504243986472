* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
html,
body {
    background-color: #ebebeb;
    color: #000;
    font-family: 'Lato', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 20px;
    text-rendering: optimizeLegibility;
    line-height: 145%;
    word-break: break-word;
}
body.modal-active {
    overflow: hidden;
}

.clearfix { zoom: 1; }
.clearfix:after {
    content: '.';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}