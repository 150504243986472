.admin{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 1%;
    .admin-header{
        h1{
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #dddfe2;
            border-radius: 5px;
        }
    }
    .admin-content{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px;
        h3{
            margin: 0 0 25px;
        }
        div{
            display: flex;
            flex-direction: column;
            button {
                margin-top: 10px;
                margin-bottom: 20px;
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;
    
                span {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                }
                    &:first-child{
                        margin-right: 20px;
                    }
                    &:last-child{
                        margin-bottom: 0px;
                    }
            }
    
            button span:after {
                content: '\00bb';
                position: absolute;
                opacity: 0;
                top: 0;
                right: -20px;
                transition: 0.5s;
            }
            button:hover span {
                padding-right: 25px;
            }
            button:hover span:after {
                opacity: 1;
                right: 0;
            }
            a{
                color: #ffffff;
                margin-top: 20px;
            }
        }
    }

}

