.contact-page{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 1%;
    .contact-page-header{
        margin-bottom: 8px;
        h1{
            font-style: normal;
            padding: 0 0 10px 0;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #dddfe2;
            border-radius: 5px;
        }
    }
            .contact-page-message{
                width: 100%;
                display: flex;
                padding: 20px;
                background-color: #ffffff;
                border: 1px solid #dddfe2;
                border-radius: 5px;
                    .contact-page-message-content{
                        width: 50%;
                        margin-top: 25px;
                        p{

                        }
                        #link-to-main-website{
                            color: #3399ff;
                        }
                        .vragen{
                            margin-top: 40px;
                            #link-to-main-website-questions{
                                color: #3399ff;
                            }
                        }
                    }
                    
                    .content-part {
                        margin-bottom: 40px;
                    }

                    .buttons {
                        display: flex;
                        flex-direction: row;
                        a {
                            color: #FFFFFF;
                            text-decoration: none;
                        }
                        .button {
                            color: #FFFFFF;
                            border: 1px solid;
                            border-radius: 20px;
                            padding: 15px 32px;
                            text-align: center;
                            text-decoration: none;
                            font-size: 16px;
                            margin: 4px 2px;
                            cursor: pointer;
                            width: 45%;
                        }
                        .buttonBlue {
                            background-color: #3399ff;
                        }
                        .buttonYellow {
                            background-color: #fdc801; 
                        }
                    }
                    .contact-page-credentials{
                        width: 50%;
            
                        .contact-page-credentials-map{
                            iframe{
                                width: 100%;
                                height: 500px;
                                border-radius: 5px;
                            }
            
                        }
                    }

            }
}
