.subjects{
    margin: 1% 15% 3% 15%;
    .subjects-header{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px;
        h1{
            padding: 10px 0 10px;
        }
    }
    .test{
        padding: 20px;
        margin: 8px 0;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px;
        p{
            margin-bottom: 20px;
        }
        .errormsg{
            color: red;
        }
        .subjects-body{
            display: grid;
            grid-template-columns:1fr 1fr 1fr;
            grid-gap: 3em;
            grid-auto-rows: 100px;
                div{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    vertical-align: middle;
                    align-items: center;
                    color: #ffffff;
                    border-radius: 15px;
                    border: 3px solid transparent;
                    background: #3399ff;
                    padding: 1em;
                    cursor: pointer;
                    user-select: none;
                    &.subject-is-checked{
                        background: #ffffff;
                        color: #3399ff;
                        border: 3px solid #3399ff;
                    }
                    h2{
                        font-size: 120%;
                        text-align: center;
                    }
                }
        }
        button{
            margin-top: 30px;
        }
    }
    .loader-animation {
        height: 60vh;
        display: flex; 
        justify-content: center;
        align-items: center;
    }
    .subjects-footer{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        p{
        }
        button{
            margin-top: 20px;
        }
    }
    .subjects-footer-admin-box{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        span{
            width: 140px;
        }
        button{
            margin-top: 20px;
            background-color: green;
        }
        button span:after {
            top: 8px;
          }
    }

    button {
        border-radius: 6px;
        background-color: #3399ff;
        border: none;
        color: #FFFFFF;
        text-align: center;
        font-size: 16px;
        padding: 10px 5px;
        width: 180px;
        transition: all 0.5s;
        cursor: pointer;

        span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
            color: #FFFFFF;
            }
        }
      button span:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: 0px;
        right: -20px;
        transition: 0.5s;
      }
      button:hover span {
        padding-right: 25px;
      }
      button:hover span:after {
        opacity: 1;
        right: 0;
      }
}