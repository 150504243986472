.exam-content{
    margin: 1% 15% 3% 15%;
    position: relative;
    min-height: 90vh;
    padding-bottom: 100px;
    .go-back{
        width: 8%;
        color: #000000;
        top: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;
        font-weight: bold;
        ion-icon{
            margin-right: 10px;
            font-size: 120%;
          }
          &:hover{
            opacity: 0.6;
          }
    }
    .exam-content-body{
        .exam-content-body-elements{
            div{
                .exam-content-body-elements-question{
                    margin-top: 25px;
                    font-size: 125%;
                    h1{
                        font-style: normal;
                        padding: 10px 0 10px 0;
                        border-bottom: 1px solid #afafaf;
                        margin-bottom: 20px
                    }
                }
                .exam-content-body-elements-image{
                    img{
                        max-width: 650px;
                        min-width: 400px;
                        min-height: 400px;
                        max-height: 500px;
                        margin-top: 25px;
                    }

                }
                .exam-content-body-elements-checkbox{
                    margin-top: 25px;
                    p{
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        input{
                            margin-right: 25px;
                        }
                        span::first-letter{
                            text-transform: uppercase;
                        }
                    }
                }
                .exam-content-body-elements-gotrough{
                    margin-top: 25px;
                    display: flex;
                    .exam-content-body-elements-gotrough-arrows{
                        margin-left: 0%;
                        flex: 1;

                        button {
                            border-radius: 4px;
                            background-color: #3399ff;
                            border: none;
                            color: #FFFFFF;
                            text-align: center;
                            font-size: 16px;
                            padding: 10px 5px;
                            width: 100px;
                            margin-right: 5px;
                            cursor: pointer;

                            &.disabled{
                                background-color: grey;
                                cursor: default;
                                outline: none;
                            }
                
                            span {
                                display: inline-block;
                                position: relative;
                                transition: 0.5s;
                                color: #FFFFFF;
                                }
                            }
                          
                    }
                    .exam-content-body-elements-gotrough-indienen{

                        button {
                            margin-bottom: 75px;
                            border-radius: 4px;
                            background-color: #3399ff;
                            border: none;
                            color: #FFFFFF;
                            text-align: center;
                            font-size: 16px;
                            padding: 10px 5px;
                            width: 180px;
                            transition: all 0.5s;
                            cursor: pointer;
                
                            span {
                                cursor: pointer;
                                display: inline-block;
                                position: relative;
                                transition: 0.5s;
                                color: #FFFFFF;
                                }
                            }
                          button span:after {
                            content: '\00bb';
                            position: absolute;
                            opacity: 0;
                            top: 0;
                            right: -20px;
                            transition: 0.5s;
                          }
                          button:hover span {
                            padding-right: 25px;
                          }
                          button:hover span:after {
                            opacity: 1;
                            right: 0;
                          }
                    }
                   
                }
                .exam-content-body-elements-confirm{
                    margin-top: 25px;
                    margin-bottom: 25px;
                    color: red;
                    text-align: center;
                    
                    button {
                        margin-top: 25px;
                        border-radius: 4px;
                        background-color: #3399ff;
                        border: none;
                        color: #FFFFFF;
                        text-align: center;
                        font-size: 16px;
                        padding: 10px 5px;
                        width: 180px;
                        transition: all 0.5s;
                        cursor: pointer;
                        margin-right: 5px;
            
                        span {
                            cursor: pointer;
                            display: inline-block;
                            position: relative;
                            transition: 0.5s;
                            color: #FFFFFF;
                            }
                        }
                      button span:after {
                        content: '\00bb';
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        right: -20px;
                        transition: 0.5s;
                      }
                      button:hover span {
                        padding-right: 25px;
                      }
                      button:hover span:after {
                        opacity: 1;
                        right: 0;
                      }
                }
                .exam-content-body-elements-navigationbar{
                    bottom: 0px;
                    display: grid;
                    width: 100%;
                    grid-template-columns: repeat(30, 1fr);
                    .exam-content-body-elements-navigationbar-overview{
                        margin-right: 2px;
                    .filledIn{
                        background-color:  #3399ff;
                        color: #FFFFFF;
                        padding: 4px 6px;
                        border-radius: 3px;
                        cursor: pointer;
                    }
                    button{
                        border-radius: 3px;
                        cursor: pointer;
                        border: 1px solid #3399ff;
                        padding: 4px 6px;
                        max-width: 37px;
                        min-width: 37px;
                    }
                    }
                }
            }
        }
        .exam-content-footer{
            height: 60vh;
            display: flex; 
            justify-content: center;
            align-items: center;
        }
    }
}