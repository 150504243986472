 .exam-overview{
     margin: 1% 15% 3% 15%;
     position: relative;
     padding: 50px 75px;
     .exam-overview-header-box{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .go-back{
            width: 8%;
            color: #000000;
            position: absolute;
            top: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            vertical-align: middle;
            align-items: center;
            font-weight: bold;
            ion-icon{
                margin-right: 10px;
                font-size: 120%;
              }
              &:hover{
                opacity: 0.6;
              }
        }
        .exam-overview-header{
            h1{
            }
        }
     }
     .exam-overview-body-box{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .exam-overview-header-index{
            flex-direction: row;
            display: flex;
             p{
                text-decoration: underline;
                font-size: 24px;
                flex: 1;
             }
         }
         .exam-overview-body{
            margin-top: 40px;
            display: flex;
            flex-direction: column;
             .exam-overview-body-content{
                display: flex;
                .exam-overview-body-content-p1{
                    a:link{
                        color: #3399ff;
                    }
                    a:visited{
                        color: #3399ff;
                    }
                    
                    flex: 1;
                }
                .exam-overview-body-content-p2{
                    flex: 1;
                }
                .exam-overview-body-content-p3{
                    flex: 1;
                }
                .exam-overview-body-content-p4{
                    flex: 1;
                }
                .exam-overview-body-content-l1{
                    flex: 1;
                    ion-icon{
                        color: #000000;
                        font-size: 25px;
                        margin-left: 30px;
                    }
                }
             }
             .loader-animation {
                display: flex; 
                justify-content: center;
                align-items: center;
            }
         }
         .exam-overview-footer{
            margin-top: 40px;
             button {
                margin-top: 25px;
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;
    
                span {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                    color: #FFFFFF;
                    }
                }
              button span:after {
                content: '+';
                position: absolute;
                opacity: 0;
                top: 0;
                right: -20px;
                transition: 0.5s;
              }
              button:hover span {
                padding-right: 25px;
              }
              button:hover span:after {
                opacity: 1;
                right: 0;
              }
         }
     }
 }
 