.made-mistakes{
    margin:5% 15% 0 15%;
    .go-back{
        top: 50px;
        position: absolute;
        color: #000000;
        font-size: 120%;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;
        font-weight: bold;
        ion-icon{
          margin-right: 10px;
          font-size: 120%;
        }
        &:hover{
          opacity: 0.6;
        }
      }
    h1{
        padding: 0 0 0px 0;
        border-bottom: 1px solid #afafaf;
        display: flex;
        .pdfbutton{
            margin-left: 420px;
            img{
                float: right;
                width: 10%;
                margin: 0;
            }
        }
    }
    h3{
        margin-top: 25px;
        font-size: 120%;
    }
    .made-mistakes-subject-button{
        button{
            background-color: #3399ff;
            color: #ffffff;
            border: none;
            margin: 2px;
            border-radius: 5px;
            padding: 10px 5px;
            cursor: pointer;
        }
    }
    .made-mistakes-content{
        margin-top: 30px;
        padding: 0 0 30px 0;
        border-bottom: 1px solid #afafaf;
        &:last-child{
            border-bottom: 0px solid;
        }
        .made-mistakes-content-vraag{
            p{
                margin: 0px 0 20px 0;
            }
        }
        .made-mistakes-content-j-f{
            .fout{
                color: red;
            }
            .juist{
                color: green;
            }
        }
        .made-mistakes-content-reden{
            p{
                margin: 30px 0 20px 0;
            }
        }
        img{ 
            max-width: 650px;
            min-width: 400px;
            min-height: 400px;
            max-height: 700px;
        }
    }
    .loader-animation {
        height: 60vh;
        display: flex; 
        justify-content: center;
        align-items: center;
    }
}