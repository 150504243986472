footer{
    font-size: 16px;
    width: 100%;
    height: 50px;
    bottom: 0;
    position: fixed;
    background-color: #3399ff;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        color: white;
        text-align: center;
        align-items: center;
    }
    
}