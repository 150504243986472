.subjectOverzicht{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 1%;
    margin-bottom: 3%;
    position: relative;
    padding: 50px 75px;
    .subjectOverzicht-header-box{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px;
        a{
            color: #000000;
            position: absolute;
            top: 0px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            vertical-align: middle;
            align-items: center;
            font-weight: bold;
            ion-icon{
                margin-right: 10px;
                font-size: 120%;
              }
              &:hover{
                opacity: 0.6;
              }
        }
        .subjectOverzicht-header{
            h1{
            }
        }
    }
    
    .subjectOverzicht-header-index-box{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px;
        .subjectOverzicht-header-index{
            display: flex;
            p{
                text-decoration: underline;
                font-size: 24px;
                flex: 1;
            }
        }
        .subjectOverzicht-body{
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            .subjectOverzicht-body-content{
                display: flex;
                .subjectOverzicht-body-content-p1{
                    flex: 1;
                }
                .subjectOverzicht-body-content-p2{
                    flex: 1;
                }
                .subjectOverzicht-body-content-p3{
                    flex: 1;
                }
                .subjectOverzicht-body-content-p4{
                    flex: 1;
                }
                .subjectOverzicht-body-content-l1{
                    flex: 1;
                    ion-icon{
                        font-size: 25px;
                        color: #000000;
                        margin-left:30px ;
                    }
                }
               
            }
        }
        .subjectOverzicht-footer{
            margin-top: 40px;
            button {
                margin-top: 25px;
                border-radius: 4px;
                background-color: #3399ff;
                border: none;
                color: #FFFFFF;
                text-align: center;
                font-size: 16px;
                padding: 10px 5px;
                width: 180px;
                transition: all 0.5s;
                cursor: pointer;
    
                span {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                    color: #FFFFFF;
                    }
                }
              button span:after {
                content: '+';
                position: absolute;
                opacity: 0;
                top: 0;
                right: -20px;
                transition: 0.5s;
              }
              button:hover span {
                padding-right: 25px;
              }
              button:hover span:after {
                opacity: 1;
                right: 0;
              }
        }
        .loader-animation {
            display: flex; 
            justify-content: center;
            align-items: center;
        }
    }
}