.exam-exam{
    margin: 1% 15% 3% 15%;
    padding: 50px 75px;
    position: relative;
    .go-back{
        top: 0px;
        position: absolute;
        color: #000000;
        font-size: 120%;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;
        font-weight: bold;
        ion-icon{
          margin-right: 10px;
          font-size: 120%;
        }
        &:hover{
          opacity: 0.6;
        }
      }
    h2{
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
    }
    .exam-exam-content{
        margin-top: 8px;
        padding: 20px;
        background-color: #ffffff;
        border: 1px solid #dddfe2;
        border-radius: 5px; 
        .test{
            margin-top: 7.5px;
            p{
                display: flex;
                span{
                    max-width: 50px;
                    min-width: 50px;
                    color: #000000;
                }
                .difficulty{
                }
            }
            ion-icon{
                color: #000000;
                padding-left: 10px;
            }
            display: flex;
            .easy{
                color: green;
            }
            .medium{
                color: orange;
            }
            .hard{
                color: red;
            }
        }
        .exam-exam-content-header{
            .exam-exam-content-header-exam{
                display: flex;
                margin-bottom: 15px;
                p{
                    &:first-child{
                        margin-right: 10px;
                    }
                }
            }
            .exam-exam-content-header-subjects{
                margin-bottom: 15px;
                #onderdelen{
                    margin-top: 0px;
                }
                button {
                    background-color: #3399ff;
                    color: #ffffff;
                    border: none;
                    margin: 2px;
                    border-radius: 5px;
                    padding: 10px 5px;
                    cursor: pointer;
                    span {
                    }
                }

            }
            .exam-exam-content-header-current{
                margin-bottom: 25px;
                display: flex;
                p{
                    text-decoration: underline;
                }
                    ion-icon{
                        margin-top: 4px;
                        margin-right: 10px;
                        font-size: 110%;
                        bottom: 0;
                    }
            }
        }
    }
}
