.account-page{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 1%;

    .account-page-message{
        width: 100%;
        h1{
            font-style: normal;
            padding: 0 0 10px 0;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #dddfe2;
            border-radius: 5px;
        }
    }
        .account-page-message-content{
            margin-top: 8px;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #dddfe2;
            border-radius: 5px;
            p{
                margin-bottom: 20px;
            }
            ion-icon{
                float: right;
                font-size: 900%;
                color: #3399ff;
            }
            .vragen{
                margin-top: 40px;
            }
            .account-page-message-password{
                button {
                    border-radius: 4px;
                    background-color: #3399ff;
                    border: none;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 16px;
                    padding: 10px 5px;
                    width: 180px;
                    transition: all 0.5s;
                    cursor: pointer;
        
                    span {
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        transition: 0.5s;
                        }
                    }
            }
            .account-page-message-content-club {
                display: flex;
                select {
                    height: 100%;
                    margin: 6px 0 0 5px;

                }
            }

            .accountF{
                height: 100vh;
                width: 100vw;
                background: rgba(#000, .7);
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                .accountForm{

                    background-color: #fff;
                    max-width: 800px;
                    padding: 3rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &-header{
                        padding: 0 0 2rem 0;
                    }
                    &-content{
                        form{
                            .fields {

                                .fields-input {
                                    display: flex;
                                    flex-direction: column;
                                    position: relative;
                                    margin: 0 0 1rem 0;
                                    label{
                                        position: absolute;
                                        top: 10px;
                                        left: 15px;
                                        font-size: .8rem;
                                        color: rgb(58, 54, 54);
                                    }
                                    input{
                                        padding: .5rem;
                                        outline: none;
                                        border: none;
                                        border-bottom: 1px solid rgb(24, 23, 23);
                                    }
                                }
                            }

                            .errors{
                                padding: 1rem 0;
                                color: red;
                            }
                        } 
                    }
                    &-btn {
                        margin-top: 20px;
                        border-radius: 4px;
                        background-color: #3399ff;
                        border: none;
                        color: #FFFFFF;
                        text-align: center;
                        font-size: 16px;
                        padding: 10px 5px;
                        width: 180px;
                        transition: all 0.5s;
                        cursor: pointer;
                        &:first-child{
                            margin-right: 20px;
                        }
                        &:hover span{
                            padding-right: 25px;
                            &::after{
                                opacity: 1;
                                right: 0;
                             }
                        }
                        span {
                            cursor: pointer;
                            display: inline-block;
                            position: relative;
                            transition: 0.5s;
                            &::after{
                                content: '\00bb';
                                position: absolute;
                                opacity: 0;
                                top: 0;
                                right: -20px;
                                transition: 0.5s;
                            }
                        }
                    }
                    #close{
                        position: absolute;
                        top: 10px;
                        right: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
}